import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./CurrentUploads.css";

export default function SelectUploads() {
  const [uploads, setUploads] = useState([]);
  const [selectedUpload, setSelectedUpload] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUploads();
  }, []);

  const fetchCurrentUploads = async () => {
    const token = localStorage.getItem("authToken");
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/intake/current`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const uploadsData = response.data;
      setUploads(uploadsData);
      if (uploadsData.length > 0) {
        handleRowSelect(uploadsData[0]); // ✅ Auto-select first row
      }
    } catch (error) {
      console.error("Error fetching uploads:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleRow = (logId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [logId]: !prev[logId],
    }));
  };

  const handleRowSelect = (upload) => {
    setSelectedUpload(upload);
    localStorage.setItem("intake_log_id", upload.intake_log_id);
  };

  const handleNextStep = () => {
    if (!selectedUpload) {
      alert("Please select an upload to proceed.");
      return;
    }
    navigate("/dashboard/resident/validate", { state: { upload: selectedUpload } });
  };

  return (
    <div className="current-uploads-container">
      <h2>Select Upload</h2>
      <p>Please choose an intake to proceed with validation.</p>

      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
          <p>Loading current uploads...</p>
        </div>
      ) : (
        <>
          <table className="upload-table">
            <thead>
              <tr>
                <th>Select</th>
                <th>Upload ID</th>
                <th>Source</th>
                <th>Path</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Status</th>
                <th>Residents</th>
              </tr>
            </thead>
            <tbody>
              {uploads.map((upload) => (
                <React.Fragment key={upload.intake_log_id}>
                  <tr
                    className={`upload-row ${selectedUpload?.intake_log_id === upload.intake_log_id ? "selected" : ""}`}
                    onClick={() => handleRowSelect(upload)}
                  >
                    <td>
                      <input
                        type="radio"
                        checked={selectedUpload?.intake_log_id === upload.intake_log_id}
                        onChange={() => handleRowSelect(upload)}
                      />
                    </td>
                    <td onClick={() => toggleRow(upload.intake_log_id)}>
                      {upload.intake_log_id.slice(0, 8)}...
                    </td>
                    <td>{upload.intake_source}</td>
                    <td>{upload.intake_path}</td>
                    <td>{new Date(upload.start_time).toLocaleString()}</td>
                    <td>
                      {upload.end_time
                        ? new Date(upload.end_time).toLocaleString()
                        : "⏳ In Progress"}
                    </td>
                    <td className={`status ${upload.intake_status.toLowerCase()}`}>
                      {upload.intake_status}
                    </td>
                    <td>{upload.resident_count}</td>
                  </tr>

                  {expandedRows[upload.intake_log_id] && (
                    <tr className="expanded-row">
                      <td colSpan="8">
                        <table className="resident-table">
                          <thead>
                            <tr>
                              <th>Resident Folder</th>
                              <th>Files</th>
                              <th>Resident JSON</th>
                              <th>Documents</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {upload.residents.map((resident, index) => (
                              <tr key={index}>
                                <td>{resident.folder_path}</td>
                                <td>{resident.num_files}</td>
                                <td>{resident.resident_json_exists ? "✅ Yes" : "❌ No"}</td>
                                <td>{resident.document_count}</td>
                                <td className={`status ${resident.status.toLowerCase()}`}>
                                  {resident.status}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* <button className="next-button" onClick={handleNextStep} disabled={!selectedUpload}>
            Next: Data Validation →
          </button> */}
        </>
      )}
    </div>
  );
}