import React, { useState } from "react";
import "./Overview.css";
import { Bar, Pie } from "react-chartjs-2";

// Chart.js setup
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

// Dummy Data
const dummySummary = {
  totalCommunities: 5,
  activeResidents: 240,
  memoryIssueResidents: 67,
};

const dummyCommunities = [
  {
    id: 1,
    name: "Sunrise Village",
    residents: 60,
    memoryCare: 15,
    trend: {
      residents: "up",
      memoryCare: "down",
    },
  },
  {
    id: 2,
    name: "Lakeside Care",
    residents: 48,
    memoryCare: 10,
    trend: {
      residents: "down",
      memoryCare: "up",
    },
  },
];

export default function CustomerDashboard() {
  const [expandedCommunityId, setExpandedCommunityId] = useState(null);

  const toggleExpand = (id) => {
    setExpandedCommunityId((prev) => (prev === id ? null : id));
  };

  const getTrendIcon = (direction) => {
    return direction === "up" ? (
      <span className="trend-icon up">↑</span>
    ) : (
      <span className="trend-icon down">↓</span>
    );
  };

  return (
    <div className="customer-dashboard">
      {/* Top Summary Cards */}
      <div className="summary-cards">
        <div className="card">Total Communities: {dummySummary.totalCommunities}</div>
        <div className="card">Active Residents: {dummySummary.activeResidents}</div>
        <div className="card">Memory Issues: {dummySummary.memoryIssueResidents}</div>
      </div>

      {/* Community Panels */}
      {dummyCommunities.map((community) => {
        const generalResidents = community.residents - community.memoryCare;
        return (
          <div key={community.id} className="accordion-section">
            {/* Header always shows name + basic stats */}
            <div className="accordion-header" onClick={() => toggleExpand(community.id)}>
              <h2>{community.name}</h2>
              <span>{expandedCommunityId === community.id ? "▲" : "▼"}</span>
            </div>

            {/* Always-visible Stats */}
            <div className="community-stats">
              <div className="stat-box">
                👥 Total: {community.residents} {getTrendIcon(community.trend.residents)}
              </div>
              <div className="stat-box">
                🧠 Memory Care: {community.memoryCare} {getTrendIcon(community.trend.memoryCare)}
              </div>
              <div className="stat-box">👤 General: {generalResidents}</div>
            </div>

            {/* Expandable Graphs */}
            {expandedCommunityId === community.id && (
              <div className="accordion-content">
                <div className="charts">
                  <div className="chart-card">
                    <Bar
                      data={{
                        labels: ["Residents", "Memory Care"],
                        datasets: [
                          {
                            label: "Residents Breakdown",
                            data: [community.residents, community.memoryCare],
                            backgroundColor: ["#4caf50", "#ff9800"],
                          },
                        ],
                      }}
                      options={{ responsive: true, maintainAspectRatio: false }}
                      height={200}
                    />
                  </div>
                  <div className="chart-card">
                    <Pie
                      data={{
                        labels: ["General", "Memory Care"],
                        datasets: [
                          {
                            data: [generalResidents, community.memoryCare],
                            backgroundColor: ["#03a9f4", "#f44336"],
                          },
                        ],
                      }}
                      options={{ responsive: true, maintainAspectRatio: false }}
                      height={200}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}