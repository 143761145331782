import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faFolderOpen,
  faCheckCircle,
  faChartLine,
  faClipboardList,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import "./ResidentOverview.css";

export default function ResidentOverview() {
  const location = useLocation();
  const navigate = useNavigate();

  const steps = [
    { path: "upload", label: "Upload Data", icon: faUpload },
    { path: "select-uploads", label: "Select Uploads", icon: faFolderOpen },
    { path: "validate", label: "Data Processing", icon: faCheckCircle },
    { path: "risk-scoring", label: "Risk & Acuity Scoring", icon: faChartLine },
    { path: "summary", label: "Summarization", icon: faClipboardList },
    { path: "staffing", label: "Staffing Model", icon: faUsers }
  ];

  // Match the path correctly even if it's a subroute
  const activeIndex = steps.findIndex(step =>
    location.pathname.includes(`/resident/${step.path}`)
  );

  const currentIndex = activeIndex === -1 ? 0 : activeIndex;

  const handleBack = () => {
    if (currentIndex > 0) {
      navigate(`/dashboard/resident/${steps[currentIndex - 1].path}`);
    }
  };

  const handleNext = () => {
    if (currentIndex < steps.length - 1) {
      navigate(`/dashboard/resident/${steps[currentIndex + 1].path}`);
    }
  };

  return (
    <div className="resident-overview-container">
      <h1>Generate Custom Staffing Model</h1>

      {/* Step Navigation with Progress Line */}
      <div className="step-navigation">
        <div className="progress-line">
          <div
            className="progress-fill"
            style={{ width: `${(currentIndex / (steps.length - 1)) * 100}%` }}
          ></div>
        </div>

        {steps.map((step, index) => (
          <Link
            key={step.path}
            to={`/dashboard/resident/${step.path}`}
            className={`step ${index === currentIndex ? "active" : index < currentIndex ? "completed" : ""}`}
          >
            <div className="step-circle">{index + 1}</div>
            <div className="step-info">
              <FontAwesomeIcon icon={step.icon} className="step-icon" />
              <span>{step.label}</span>
            </div>
          </Link>
        ))}
      </div>

      {/* Navigation Buttons */}
      <div className="step-buttons">
        <button onClick={handleBack} disabled={currentIndex === 0}>
          ← Back
        </button>
        <button onClick={handleNext} disabled={currentIndex === steps.length - 1}>
          Next →
        </button>
      </div>

      {/* Step Content */}
      <div className="resident-content">
        <Outlet />
      </div>
    </div>
  );
}