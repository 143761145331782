import React, { useState } from "react";
import axios from "axios";
import "./UploadLocal.css";
import { FaUpload, FaPlay } from "react-icons/fa";

export default function UploadLocal() {
  const [residentFolders, setResidentFolders] = useState([]);
  const [intakeLogId, setIntakeLogId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [folderStatuses, setFolderStatuses] = useState({});

  const handleFolderSelect = async (event) => {
    const files = event.target.files;
    if (!files.length) {
      console.error("No files selected.");
      return;
    }
  
    // ✅ Check if customer.json exists at root level
    const hasCustomerJson = Array.from(files).some((file) => {
      const parts = file.webkitRelativePath.split("/");
      return parts.length === 2 && file.name.toLowerCase() === "customer.json";
    });
  
    if (!hasCustomerJson) {
      alert("Missing 'customer.json' file in the selected folder. Please include it before uploading.");
      return;
    }
  
    const token = localStorage.getItem("authToken");
    const customerId = localStorage.getItem("customerId");
  
    if (!customerId) {
      console.error("Customer ID missing from localStorage.");
      return;
    }
  
    const firstFilePath = files[0].webkitRelativePath;
    const splitPath = firstFilePath.split("/");
    splitPath.pop();
    const basePath = splitPath.join("/");
  
    console.log("Detected base intake path:", basePath);

    try {
      console.log("Starting intake process...");

      const intakeResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/intake/start`,
        { intake_source: "directory_upload", intake_path: basePath, customer_id: customerId }, // ✅ Corrected customerId
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const logId = intakeResponse.data.intake_log_id;
      setIntakeLogId(logId);
      console.log("Intake started. Log ID:", logId);

      const folderData = {};
      for (let file of files) {
        const pathParts = file.webkitRelativePath.split("/");
        if (pathParts.length < 2) continue;

        const residentFolder = pathParts[1];
        if (residentFolder.toLowerCase() === ".ds_store" || file.name.toLowerCase() === ".ds_store"  || 
            file.name.toLowerCase() === "dummy.txt" || file.name.toLowerCase() === "customer.json") {
          continue;
        }

        if (!folderData[residentFolder]) {
          folderData[residentFolder] = {
            name: residentFolder,
            files: [],
            hasResidentJson: false,
          };
        }

        folderData[residentFolder].files.push(file.name);

        if (file.name.toLowerCase() === "resident.json") {
          folderData[residentFolder].hasResidentJson = true;
        }
      }

      const residentFoldersArray = Object.values(folderData);
      setResidentFolders(residentFoldersArray);
      console.log("Resident Folders:", residentFoldersArray);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/intake/process_residents`,
        {
          intake_log_id: logId,
          customer_id: customerId, // ✅ Corrected customerId
          intake_path: basePath,
          residents: residentFoldersArray.map((folder) => ({
            name: folder.name,
            no_of_files: folder.files.length,
            resident_json_exists: folder.hasResidentJson,
          })),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Resident Folders Processed Successfully");
    } catch (error) {
      console.error("Error processing intake:", error.response?.data || error.message);
    }
  };

  const handleProcessAll = async () => {
    if (!intakeLogId) {
      console.error("No intake log ID available.");
      return;
    }

    setProcessing(true);
    console.log("Processing all resident folders...");

    try {
      const token = localStorage.getItem("authToken");
      const customerId = localStorage.getItem("customerId"); // ✅ Fetch customerId properly

      for (let folder of residentFolders) {
        console.log("Processing folder:", folder.name);

        if (!folder.name) {
          console.error("Skipping folder due to missing name.");
          continue;
        }

        setFolderStatuses((prev) => ({ ...prev, [folder.name]: "Processing..." }));

        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/intake/process_folder`,
          { intake_log_id: intakeLogId, resident_folder: folder.name, customer_id: customerId }, // ✅ Corrected customerId
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setFolderStatuses((prev) => ({ ...prev, [folder.name]: "✅ Completed" }));
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/intake/update_intake_status`,
        { intake_log_id: intakeLogId, customer_id: customerId, status:"UPLOAD COMPLETE" }, // ✅ Corrected customerId
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProcessing(false);
      console.log("Intake process completed successfully");
    } catch (error) {
      console.error("Error processing residents:", error.response?.data || error.message);
      setProcessing(false);
    }
  };

  return (
    <div className="upload-local-container">
      <h2>Upload Resident Data</h2>
      <p className="upload-local-description">
        Select a <strong>Site Folder</strong> that contains <strong>Resident Folders</strong>. Each <strong>Resident Folder</strong> should have a <strong>resident.json</strong> file.
      </p>

      <div className="upload-local-options">
        <div className="upload-local-option">
          <FaUpload className="upload-local-icon" />
          <h3>Upload from Local Computer</h3>
          <p>Select a <strong>site folder</strong> that contains resident/patient directories.</p>
          <input type="file" className="upload-local-input" webkitdirectory="true" mozdirectory="true" onChange={handleFolderSelect} />
        </div>
      </div>

      {residentFolders.length > 0 && (
        <div className="upload-local-list">
          <h3>Detected Resident Folders</h3>
          <table className="upload-local-table">
            <thead>
              <tr>
                <th>Resident Folder</th>
                <th>No. of Files</th>
                <th>Resident JSON</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {residentFolders.map((folder, index) => (
                <tr key={index}>
                  <td>{folder.name}</td>
                  <td>{folder.files.length}</td>
                  <td>{folder.hasResidentJson ? "✅ Yes" : "❌ No"}</td>
                  <td>{folderStatuses[folder.name] || "Pending"}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <button className="upload-local-process-button" onClick={handleProcessAll} disabled={processing}>
            <FaPlay /> {processing ? "Processing..." : "Start Processing"}
          </button>
        </div>
      )}
    </div>
  );
}