import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import Overview from "../components/Dashboard/Overview";
import ResidentOverview from "../components/ResidentAnalysis/ResidentOverview";
import StaffingTemplate from "../components/Dashboard/StaffingTemplate";
import LoginPage from "../components/LoginPage/LoginPage";

// Resident Analysis Step Pages
import UploadData from "../components/ResidentAnalysis/UploadData/UploadData";
import UploadFromLocal from "../components/ResidentAnalysis/UploadData/UploadLocal";
import UploadToS3 from "../components/ResidentAnalysis/UploadData/UploadS3";
import UploadAPI from "../components/ResidentAnalysis/UploadData/UploadAPI";
import CurrentUploads from "../components/ResidentAnalysis/UploadData/SelectUploads"; // Now for Select Uploads step
import DataValidation from "../components/ResidentAnalysis/DataValidation";
import RiskScoring from "../components/ResidentAnalysis/RiskScoring";
import Summarization from "../components/ResidentAnalysis/Summarization";
import StaffingModelStep from "../components/ResidentAnalysis/StaffingModel";

export default function AppRoutes({ isLoggedIn, menu, customerName, onLogin, onLogout }) {
  return (
    <Routes>
      {isLoggedIn ? (
        <>
          {/* 🏠 Dashboard Layout with Nested Routes */}
          <Route path="/dashboard" element={<Dashboard menu={menu} customerName={customerName} onLogout={onLogout} />}>
            <Route index element={<ResidentOverview />} />
            <Route path="overview" element={<Overview />} />
            <Route path="staff" element={<StaffingTemplate />} />

            {/* 📌 Resident Analysis Steps */}
            <Route path="resident" element={<ResidentOverview />}>
              <Route index element={<UploadData />} /> {/* Default step */}

              {/* Upload Data Section */}
              <Route path="upload/*" element={<UploadData />}>
                <Route index element={<Navigate to="local" replace />} />
                <Route path="local" element={<UploadFromLocal />} />
                <Route path="s3" element={<UploadToS3 />} />
                <Route path="api" element={<UploadAPI />} />
              </Route>

              {/* ✅ Select Uploads step */}
              <Route path="select-uploads" element={<CurrentUploads />} />

              <Route path="validate" element={<DataValidation />} />
              <Route path="risk-scoring" element={<RiskScoring />} />
              <Route path="summary" element={<Summarization />} />
              <Route path="staffing" element={<StaffingModelStep />} />
            </Route>
          </Route>

          {/* 🛑 Default Redirect to Dashboard Overview */}
          <Route path="*" element={<Navigate to="/dashboard/overview" />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<LoginPage onLogin={onLogin} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
}